import React from 'react';
import { graphql } from 'gatsby';

import MarketingLayout from '@/components/common/hl-marketing-layout';
import Hero from '@/components/hallucination-index-2024/model-page/hero';
import ModelPerformance from '@/components/hallucination-index-2024/model-page/model-performance';
import ScRag from '@/components/hallucination-index-2024/model-page/sc-rag';
import McLcRag from '@/components/hallucination-index-2024/model-page/mc-lc-rag';
import PerformanceSummary from '@/components/hallucination-index-2024/model-page/performance-summary';
import PreFooter from '@/components/hallucination-index-2024/pre-footer';
import { modelMetadata } from '@/utils/constants';

import {
  formHiModelsData,
  checkIfModelHasLcrImage,
  checkIfModelHasMcrImage,
} from '@/components/hallucination-index-2024/model-data-helpers';
import { IHiModel } from '@/components/hallucination-index-2024/model-data-helpers';
import SEO from '@/components/seo';

export interface IModelTemplateData extends IHiModel {
  ms_marco: number;
  drop: number;
  convfinqa: number;
  hotpot_qa: number;
  scRag: number;
  mcRag: number;
  lcRag: number;
  dropResponseLen: number;
  hotspotResponseLen: number;
  msMarcoResponseLen: number;
  confinqaResponseLen: number;
}

type ScrResponseLength = {
  convfinqa: string;
  drop: string;
  hotpot_qa: string;
  id: string;
  ms_marco: string;
  field1: string;
}[];

const ModelTemplatePage = ({ data }) => {
  const { model, scr, mcr, lcr, allScrResponseLenCsv } = data;
  const formattedModelData = formHiModelsData(model)[0];
  const modelResponseLength: ScrResponseLength[number] =
    allScrResponseLenCsv.nodes.find(
      (i) => i.field1 === formattedModelData.name,
    );
  const modelData = {
    ...formattedModelData,
    ms_marco: parseFloat(scr.nodes[0]?.ms_marco),
    drop: parseFloat(scr.nodes[0]?.drop),
    convfinqa: parseFloat(scr.nodes[0]?.convfinqa),
    hotpot_qa: parseFloat(scr.nodes[0]?.hotpot_qa),
    scRag: parseFloat(scr.nodes[0]?.model_avg),
    mcRag: parseFloat(mcr.nodes[0]?.model_avg),
    lcRag: parseFloat(lcr.nodes[0]?.model_avg),
    dropResponseLen: parseFloat(modelResponseLength?.drop),
    hotspotResponseLen: parseFloat(modelResponseLength?.hotpot_qa),
    msMarcoResponseLen: parseFloat(modelResponseLength?.ms_marco),
    confinqaResponseLen: parseFloat(modelResponseLength?.convfinqa),
  } as IModelTemplateData;
  return (
    <MarketingLayout>
      <div className="bg-smoky-black">
        <Hero modelData={modelData} />
      </div>
      <ModelPerformance modelData={modelData} />
      <ScRag modelData={modelData} />
      {checkIfModelHasMcrImage(modelData.name) && (
        <McLcRag modelData={modelData} type="mcr" title="Medium Context RAG" />
      )}
      {checkIfModelHasLcrImage(modelData.name) && (
        <McLcRag modelData={modelData} type="lcr" title="Long Context RAG" />
      )}
      <PerformanceSummary modelData={modelData} />
      <PreFooter />
    </MarketingLayout>
  );
};

export const query = graphql`
  query ModelTemplate($modelName: String!) {
    model: allHiModelsCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        Developer
        Open_Closed
        License
        Model_parameters
        Supported_context_length
        _xMxpromptxtokens
        _xMxresponsextokens
        Name_for_charts
      }
    }

    scr: allScrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        id
        convfinqa
        hotpot_qa
        drop
        ms_marco
        model_avg
      }
    }
    mcr: allMcrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        _5000
        _10000
        _15000
        _20000
        _25000
        prompt_type
        model_avg
        model_type
      }
    }
    lcr: allLcrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        _40000
        _60000
        _80000
        _100000
        prompt_type
        model_avg
        model_type
      }
    }
    allScrResponseLenCsv {
      nodes {
        convfinqa
        drop
        hotpot_qa
        id
        ms_marco
        field1
      }
    }
  }
`;

export const Head = (props) => {
  const slug = props?.pageContext?.slug;

  const metaTags = modelMetadata?.[slug];
  if (!metaTags) return null;

  return (
    <>
      <SEO title={metaTags.title} description={metaTags.description} />
    </>
  );
};

export default ModelTemplatePage;
